import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import store from '@/store'
import { relocateToLogin } from '@/helpers/navguard'
import VueCookies from 'vue-cookies'
import Vue from 'vue'

const instance = axios.create({
  baseURL: process.env.BASE_URL // BASE_URL corresponds to the publicPath option in vue.config.js
})

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (!store.getters.isAuthenticating && (error.response === undefined || error.response.status === 401 || error.response.status === 403)) {
    relocateToLogin()
  }
  return Promise.reject(error)
})

// For all request, we retrieve the bearer token from the cookie and inject it
instance.interceptors.request.use(
  config => {
    if ((document.cookie === null || document.cookie === undefined || document.cookie.length === 0)) {
      return config
    } else {
      if (Vue.$cookies === undefined) {
        Vue.use(VueCookies)
      }

      if (Vue.$cookies.isKey(store.getters.getCookieName)) {
        const cookieValue = Vue.$cookies.get(store.getters.getCookieName)
        const decodeJwt = jwtDecode(cookieValue)
        if (decodeJwt) {
          config.headers.Authorization = `Bearer ${decodeJwt.accessToken}`
        }
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default {
  authenticate () {
    return instance.get('api/user', {
      // suppress the www-authenticate header response from backend
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    })
  },
  getConfig (credentials) {
    return instance.get('api/config')
  },
  login (credentials) {
    return instance.post('api/login', credentials)
  },
  logout () {
    return instance.post('api/logout')
  },
  getInstances (radioId, siteId, groupId) {
    return instance.get('api/instances?radioId=' + radioId + '&siteId=' + siteId + '&groupId=' + groupId)
  },
  getRadios () {
    return instance.get('api/radios')
  },
  retrieveLaunchUrl (appInstance, radioId, siteId, groupId) {
    return instance.post('api/user/launchUrl/retrieve', {
      instance: appInstance,
      radioId,
      siteId,
      groupId
    })
  },
  getSites (radioId) {
    return instance.get('api/radios/' + radioId + '/sites')
  },
  getGroupsWithRadioLinksByUserId (userId) {
    return instance.get('api/user/' + userId + '/getGroupsWithRadioLinksByUserId')
  }
}
